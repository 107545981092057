/**
 * @generated SignedSource<<6acbd936f94a7df07f4ba804a4acb62e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSbSharedItemTracking_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_itemSearch" | "useSearchCorrections_itemSearch">;
  readonly " $fragmentType": "useSbSharedItemTracking_itemSearch";
};
export type useSbSharedItemTracking_itemSearch$key = {
  readonly " $data"?: useSbSharedItemTracking_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSbSharedItemTracking_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSbSharedItemTracking_itemSearch",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "ecommerceTracking_itemSearch",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchSponsored",
          "kind": "LinkedField",
          "name": "sponsored",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchSponsoredMetadata",
              "kind": "LinkedField",
              "name": "metadata",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "itemId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "impressionTrackerLink",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clickTrackerLink",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSearchCorrections_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "470c0809f7c0d421704ee00b4efed8c8";

export default node;
