import { useEffect, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { trackEvent } from 'dibs-tracking';

export function getOriginalSearchValue(appliedFilters = []) {
    return appliedFilters.find(({ name }) => name === 'originalSearch')?.values?.[0]?.urlLabel;
}

export function useSearchCorrections({ itemSearch, includeTracking }) {
    const data = useFragment(
        graphql`
            fragment useSearchCorrections_itemSearch on ItemSearchQueryConnection {
                searchCorrections {
                    semanticSearchTerm
                    classificationLevel
                    classificationSearchTerm
                }
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        itemSearch
    );

    const { searchCorrections = {} } = data || {};

    const { current: searchCorrectionTerm } = useRef(
        searchCorrections.semanticSearchTerm ||
            searchCorrections.classificationSearchTerm ||
            getOriginalSearchValue(data?.appliedFilters)
    );

    const classificationTrackingRef = useRef({
        includeClassificationTracking: includeTracking,
        classificationLevel: searchCorrections.classificationLevel,
        classificationSearchTerm: searchCorrections.classificationSearchTerm,
    });

    useEffect(() => {
        const {
            current: {
                classificationLevel,
                classificationSearchTerm,
                includeClassificationTracking,
            },
        } = classificationTrackingRef;
        if (classificationLevel && classificationSearchTerm && includeClassificationTracking) {
            trackEvent({
                category: 'product list',
                action: 'classified search',
                label: `${classificationSearchTerm} | ${classificationLevel}`,
            });
        }
    }, []);

    return [searchCorrectionTerm];
}

/**
 * For class components, since they can't use hooks :`(
 */
export function SearchCorrectionsProvider({ itemSearch, children }) {
    const [searchCorrectionTerm] = useSearchCorrections({ itemSearch, includeTracking: true });

    return children({ searchCorrectionTerm });
}
