import { FC, ReactNode, useCallback, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { viewMore, viewLess } from '../../finding/sbMessages';
import { PageLayout } from 'dibs-elements/exports/PageLayout';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { Button } from 'dibs-elements/exports/Button';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { RESULTS_FIRST_ROW_COUNT } from '../../constants/sbConstants';
import { useViewMore } from '../../finding/hooks/viewMore/useViewMore';
import { locations } from '../../utils/tracking/shared/favoritesTracking';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { FullPageWidth } from '../../components/global/FullPageWidth/FullPageWidth';
import { FavoritesProvider } from '../../components/global/FavoriteIcons/FavoritesProvider';
import { FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import classnames from 'classnames';

import styles from './SharedRelatedItemsWrapper.scss';

import { SharedRelatedItemsWrapper_user$key } from './__generated__/SharedRelatedItemsWrapper_user.graphql';

const SMALL_TILE_TYPE = 'imageSmall';
const X_SMALL_TILE_TYPE = 'imageXSmall';

export type ChildrenProps = {
    currency: string;
    favorites: FavoritesProviderChildrenProps;
    imageSize: typeof SMALL_TILE_TYPE | typeof X_SMALL_TILE_TYPE;
    itemClassName: (index: number) => {
        wrapperClassName?: string | null;
        favoritesClassName?: string | null;
    };
};

export type Props = {
    user: SharedRelatedItemsWrapper_user$key;
    children: (props: ChildrenProps) => ReactNode;
    header: NonNullable<ReturnType<typeof FormattedMessage>>;
    itemIds: Array<Maybe<string>>;
    isMobile: boolean;
    isClient: boolean;
    isDark?: boolean;
    showSwiper?: boolean;
    initialCount?: number;
    onItemsImpression: ({
        startIndex,
        itemsLengthToShow,
    }: {
        startIndex: number;
        itemsLengthToShow: number;
    }) => void;
};

export const SharedRelatedItemsWrapper: FC<Props> = ({
    user: userRef,
    children,
    header,
    itemIds,
    isMobile,
    isClient,
    isDark,
    showSwiper = false,
    initialCount = RESULTS_FIRST_ROW_COUNT * 2,
    onItemsImpression = () => {},
}) => {
    const user = useFragment(
        graphql`
            fragment SharedRelatedItemsWrapper_user on User {
                serviceId
            }
        `,
        userRef
    );

    const totalCount = itemIds?.length;

    const [isViewMoreImpressionFired, setIsViewMoreImpressionFired] = useState(false);
    const {
        viewMoreEl,
        dispatch: toggleViewMore,
        visibleCount,
    } = useViewMore<HTMLButtonElement>({
        totalCount,
        revealCount: totalCount - initialCount,
        initialCount,
    });

    const itemClassName = useCallback(
        (index: number) => {
            if (showSwiper) {
                return {
                    favoritesClassName: styles.swiperItemFavorites,
                };
            } else {
                return {
                    customClassName: classnames({
                        [styles.disablePadding]: isDark,
                        [styles.respItemGrid]: !isMobile,
                        [styles.mobileItemGrid]: isMobile,
                        [styles.hideTile]: index >= visibleCount,
                    }),
                };
            }
        },
        [showSwiper, visibleCount, isMobile, isDark]
    );

    const trackViewMoreItemsImpression = (): void => {
        if (!isViewMoreImpressionFired) {
            onItemsImpression({
                startIndex: initialCount,
                itemsLengthToShow: totalCount - initialCount,
            });
            setIsViewMoreImpressionFired(true);
        }
    };

    const imageSize = isMobile ? X_SMALL_TILE_TYPE : SMALL_TILE_TYPE;

    return (
        <>
            <VisibilityTracker
                onVisibilityChange={({ isVisible }) => {
                    if (isVisible) {
                        onItemsImpression({
                            startIndex: 0,
                            itemsLengthToShow: visibleCount,
                        });
                    }
                }}
            />
            <FavoritesProvider
                disable={!isClient}
                itemIds={itemIds.filter(filterFalsy)}
                location={locations.SEARCH_BROWSE}
                userId={user?.serviceId}
                fetchFolder={!isMobile}
            >
                {({ props: favorites }: { props: FavoritesProviderChildrenProps }) => (
                    <CurrencyProvider
                        render={({ currency }: { currency: ChildrenProps['currency'] }) => (
                            <QuickViewProvider>
                                <PageLayout setViewportBackground={isDark}>
                                    <div
                                        className={classnames(styles.container, {
                                            [styles.topSpacer]: !isDark && !showSwiper,
                                        })}
                                    >
                                        <HeadingLevel>
                                            {Heading => (
                                                <Heading className={styles.header}>
                                                    {header}
                                                </Heading>
                                            )}
                                        </HeadingLevel>
                                        {showSwiper ? (
                                            <FullPageWidth>
                                                <Swiper itemsPerPage={2.5} itemsTopAlign>
                                                    {children({
                                                        currency,
                                                        favorites,
                                                        imageSize,
                                                        itemClassName,
                                                    })}
                                                </Swiper>
                                            </FullPageWidth>
                                        ) : (
                                            <div className={styles.itemGrid}>
                                                {children({
                                                    currency,
                                                    favorites,
                                                    imageSize,
                                                    itemClassName,
                                                })}
                                            </div>
                                        )}
                                        {!showSwiper && totalCount > initialCount && (
                                            <div className={styles.viewMoreSection}>
                                                <Button
                                                    className={styles.viewMoreButton}
                                                    buttonRef={viewMoreEl}
                                                    size="medium"
                                                    type="secondary"
                                                    onClick={() => {
                                                        trackViewMoreItemsImpression();
                                                        toggleViewMore();
                                                    }}
                                                >
                                                    {visibleCount < totalCount
                                                        ? viewMore
                                                        : viewLess}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </PageLayout>
                            </QuickViewProvider>
                        )}
                    />
                )}
            </FavoritesProvider>
        </>
    );
};
